import React from "react";
import Layout from "../components/layout";
import { graphql, useStaticQuery } from "gatsby";
import { renderRichText } from "gatsby-source-contentful/rich-text";
import testimonialStyles from "./testimonials.module.scss";
import Content from "../components/content";


const TestimonialsPage = () => {

  const data = useStaticQuery (graphql`
    query {
      allContentfulTestimonials {
        edges {
          node {
            body {
              raw
            }
            name
            profile {
              file {
                fileName
                url
              }
            }
          }
        }
      }
    }
    `)


  return (
    <Layout>
      <Content>
        <h1>Testimonials</h1>
        <div>
          <ol className={testimonialStyles.cards}>
              {data.allContentfulTestimonials.edges.map((edge) => {
                  return (
                      <li className={testimonialStyles.cardItem}>
                          <div className={testimonialStyles.card}>  
                            <div>
                                <div>
                                    <p className={testimonialStyles.body}>{renderRichText(edge.node.body)}</p>
                                    <div className={testimonialStyles.profile}>
                                      <div>
                                        <img className={testimonialStyles.photo} src={edge.node.profile.file.url} alt={edge.node.profile.file.fileName} />
                                        </div>
                                        <div>
                                          <p className={testimonialStyles.name}> {edge.node.name}</p>
                                        </div>
                                    </div>
                                </div>
                            </div>
                          </div>
                      </li>
                  )
              })}
          </ol>
        </div>
      </Content>
    </Layout>
  )
}

export default TestimonialsPage;
 